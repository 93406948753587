export const RevealIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.61155 2.22223C7.84599 2.22223 7.22266 2.84556 7.22266 3.61112C7.22266 4.37667 7.84599 5.00001 8.61155 5.00001C9.3771 5.00001 10.0004 4.37667 10.0004 3.61112C10.0004 2.84556 9.3771 2.22223 8.61155 2.22223Z"
        fill="#FF7300"
      />
      <path
        d="M4.56551 12.5823L7.55107 8.52114L6.89551 7.63336C6.26107 6.77447 4.84996 6.77447 4.21551 7.63336L1.14551 11.7867C0.768845 12.2956 0.712179 12.9634 0.997734 13.5278C1.28218 14.0923 1.85329 14.4434 2.48551 14.4434H3.87218C3.92329 13.7834 4.15551 13.1389 4.56662 12.5811L4.56551 12.5823Z"
        fill="#FF7300"
      />
      <path
        d="M16.9699 16.6667H7.47439C6.73662 16.6667 6.07217 16.2578 5.73884 15.6C5.40661 14.9422 5.47106 14.1644 5.90773 13.5711L10.6555 7.11334C11.3955 6.10667 13.0477 6.10667 13.7888 7.11334L18.5366 13.5711C18.9744 14.1644 19.0388 14.9422 18.7055 15.6C18.3722 16.2578 17.7077 16.6667 16.9699 16.6667Z"
        fill="#FF7300"
      />
    </svg>
  )
}
