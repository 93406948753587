export const RedDotIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <circle cx="9" cy="9" r="9" fill="#FC8181" fillOpacity="0.2" />
      <circle cx="9" cy="9" r="5" fill="#F34E4B" />
    </svg>
  )
}
