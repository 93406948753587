export const LiveIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="14" height="14" rx="7" fill="#C7FF00" fillOpacity="0.12" />
    <rect
      x="2.91663"
      y="2.91675"
      width="8.16667"
      height="8.16667"
      rx="4.08333"
      fill="#C7FF00"
    />
  </svg>
)
