export const AddIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.8334 10.8332H10.8334V15.8332H9.16669V10.8332H4.16669V9.1665H9.16669V4.1665H10.8334V9.1665H15.8334V10.8332Z"
        fill="currentColor"
      />
    </svg>
  )
}
