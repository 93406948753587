export const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <g clipPath="url(#clip0_1297_29295)">
        <path
          d="M7.86927 3.125L1.70552 9.28875C1.6495 9.34483 1.6105 9.41561 1.59302 9.49292L0.759684 13.2429C0.746138 13.3039 0.746457 13.3671 0.760619 13.4279C0.774781 13.4887 0.802424 13.5455 0.841504 13.5942C0.880584 13.6429 0.930103 13.6821 0.986402 13.7091C1.0427 13.7361 1.10434 13.7501 1.16677 13.75C1.19715 13.7499 1.22745 13.7467 1.25718 13.7404L5.00718 12.9071C5.08449 12.8896 5.15527 12.8506 5.21135 12.7946L11.3751 6.63083L7.86927 3.125Z"
          fill="currentColor"
        />
        <path
          d="M12.8838 3.35468L11.1455 1.61593C10.9107 1.3822 10.593 1.25098 10.2617 1.25098C9.93045 1.25098 9.61269 1.3822 9.37796 1.61593L8.45837 2.53593L11.9642 6.04176L12.8842 5.12218C13.1178 4.88734 13.2489 4.56956 13.2488 4.23834C13.2487 3.90712 13.1175 3.58941 12.8838 3.35468Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1297_29295">
          <rect
            width="13.3333"
            height="13.3333"
            fill="currentColor"
            transform="translate(0.333374 0.833008)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
