import { Avatar, Spin } from 'antd'

import LoadingSuccessImg from './loading-success.png'

type LoadingSuccessIconProps = {
  size?: number
}

const LoadingSuccessIcon = ({ size = 21 }: LoadingSuccessIconProps) => {
  return (
    <Spin
      indicator={
        <Avatar
          shape="circle"
          size={size}
          src={LoadingSuccessImg.src}
          alt="loading-icon"
          className="space-3-spin-dot-spin"
        />
      }
    />
  )
}

export default LoadingSuccessIcon
