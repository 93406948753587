export const LaunchpadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1832 1.30737L5.34392 13.447C5.24516 13.6223 5.43793 13.8173 5.61434 13.7205L12.2607 10.0754C12.3203 10.0427 12.3924 10.0425 12.4522 10.0749L19.1884 13.7237C19.3653 13.8195 19.5571 13.6235 19.4574 13.4487L12.5311 1.30645C12.4541 1.1715 12.2594 1.17201 12.1832 1.30737Z"
      stroke="currentColor"
      strokeWidth="1.2"
    />
    <path
      d="M9.58008 15.2V19.7"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M15.2002 15.2V19.7"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M12.3564 13L12.3564 22"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </svg>
)
