import { CSSProperties } from 'react'

type DownArrowIconProps = {
  style?: CSSProperties
}

export const DownArrowIcon = ({ style }: DownArrowIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g clipPath="url(#clip0_9780_222651)">
      <path
        d="M18.5 9.5L12 16.5L5.5 9.5"
        stroke="currentColor"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_9780_222651">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(4.5 4.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
