type ExclamationMarkIconProps = {
  className?: string
}

export const ExclamationMarkIcon = ({
  className,
}: ExclamationMarkIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      className={className}
    >
      <path
        d="M52.8571 0.5H7.14286C3.19857 0.5 0 3.69857 0 7.64286V53.3571C0 57.3014 3.19857 60.5 7.14286 60.5H52.8571C56.8014 60.5 60 57.3014 60 53.3571V7.64286C60 3.69857 56.8014 0.5 52.8571 0.5ZM27.1429 11.9286C27.1429 11.14 27.7829 10.5 28.5714 10.5H31.4286C32.2171 10.5 32.8571 11.14 32.8571 11.9286V37.6429C32.8571 38.4314 32.2171 39.0714 31.4286 39.0714H28.5714C27.7829 39.0714 27.1429 38.4314 27.1429 37.6429V11.9286ZM30 51.9286C28.0271 51.9286 26.4286 50.33 26.4286 48.3571C26.4286 46.3843 28.0271 44.7857 30 44.7857C31.9729 44.7857 33.5714 46.3843 33.5714 48.3571C33.5714 50.33 31.9729 51.9286 30 51.9286Z"
        fill="#C4C6CD"
      />
    </svg>
  )
}
