type UploadIconProps = {
  width?: number
  height?: number
}

export const UploadIcon = ({ width, height }: UploadIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? 20}
      height={height ?? 20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_2088_6395)">
        <path
          d="M13.505 13.8304L10.1717 10.4971L6.83838 13.8304"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1714 10.4971V17.9971"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.1628 15.823C17.9756 15.3798 18.6176 14.6787 18.9877 13.8301C19.3577 12.9816 19.4346 12.034 19.2063 11.1369C18.9779 10.2397 18.4573 9.44421 17.7267 8.87582C16.996 8.30744 16.0968 7.99856 15.1711 7.99795H14.1211C13.8689 7.02232 13.3987 6.11656 12.7461 5.34878C12.0934 4.58099 11.2751 3.97116 10.3529 3.56512C9.43057 3.15909 8.42823 2.96742 7.42121 3.00452C6.41418 3.04162 5.42866 3.30654 4.53876 3.77934C3.64885 4.25214 2.8777 4.92054 2.28329 5.73427C1.68889 6.548 1.28668 7.4859 1.10693 8.47745C0.927168 9.469 0.97453 10.4884 1.24545 11.459C1.51638 12.4296 2.00381 13.3262 2.67111 14.0813"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2088_6395">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
