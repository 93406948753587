export const QuestionMarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={37}
      height={57}
      viewBox="0 0 37 57"
      fill="none"
    >
      <g filter="url(#filter0_i_30588_211008)">
        <path
          d="M5.52697 1.38507H31.4733C33.7397 1.38507 35.5613 3.24128 35.5613 5.51936V22.6612C35.5613 24.2643 34.6714 25.7268 33.2531 26.458L21.7677 32.3501C21.0169 32.7438 20.5441 33.5172 20.5441 34.375V41.9686H11.798V32.3922C11.798 30.072 13.1329 27.9627 15.2047 26.9783L25.6194 22.0565C26.8152 21.494 27.5799 20.2706 27.5799 18.9347V9.69584C27.5799 9.02085 27.0376 8.48649 26.3841 8.48649H10.2824C9.61497 8.48649 9.08659 9.03491 9.08659 9.69584V17.5285C9.08659 18.2878 8.48868 18.8925 7.73782 18.8925H2.78773C2.03687 18.8925 1.43896 18.2878 1.43896 17.5285V5.54749C1.43896 3.24128 3.27439 1.38507 5.52697 1.38507Z"
          fill="white"
          fillOpacity="0.05"
        />
      </g>
      <path
        d="M5.52697 1.38507H31.4733C33.7397 1.38507 35.5613 3.24128 35.5613 5.51936V22.6612C35.5613 24.2643 34.6714 25.7268 33.2531 26.458L21.7677 32.3501C21.0169 32.7438 20.5441 33.5172 20.5441 34.375V41.9686H11.798V32.3922C11.798 30.072 13.1329 27.9627 15.2047 26.9783L25.6194 22.0565C26.8152 21.494 27.5799 20.2706 27.5799 18.9347V9.69584C27.5799 9.02085 27.0376 8.48649 26.3841 8.48649H10.2824C9.61497 8.48649 9.08659 9.03491 9.08659 9.69584V17.5285C9.08659 18.2878 8.48868 18.8925 7.73782 18.8925H2.78773C2.03687 18.8925 1.43896 18.2878 1.43896 17.5285V5.54749C1.43896 3.24128 3.27439 1.38507 5.52697 1.38507Z"
        stroke="#0FDBD1"
        strokeWidth="1.8"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter1_i_30588_211008)">
        <path
          d="M20.7743 47.6557H11.9292V55.6149H20.7743V47.6557Z"
          fill="white"
          fillOpacity="0.05"
        />
      </g>
      <path
        d="M20.7743 47.6557H11.9292V55.6149H20.7743V47.6557Z"
        stroke="#0FDBD1"
        strokeWidth="1.8"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_i_30588_211008"
          x="0.539062"
          y="0.485046"
          width="35.9224"
          height="46.3835"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={6} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0588235 0 0 0 0 0.858824 0 0 0 0 0.819608 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_30588_211008"
          />
        </filter>
        <filter
          id="filter1_i_30588_211008"
          x="11.0293"
          y="46.7557"
          width="10.645"
          height="13.7592"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={6} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0588235 0 0 0 0 0.858824 0 0 0 0 0.819608 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_30588_211008"
          />
        </filter>
      </defs>
    </svg>
  )
}
